export default [
    {
        id: 1,
        title: "Oculus Quest All-in-one VR Gaming Headset – 64GB",
        image: "https://images-na.ssl-images-amazon.com/images/I/51odsYyURHL._SX522_.jpg",
        price: 582,
        rating: 4.7,
    },
    {
        id: 2,
        title: "ASUS VivoBook 15 Thin and Light Laptop F512JA-AS34",
        image: "https://images-na.ssl-images-amazon.com/images/I/816ioPpcNEL._AC_SL1500_.jpg",
        price: 529,
        rating: 4.5,
    },
    {
        id: 3,
        title: "AmazonBasics Blue, White, and Yellow Microfiber Cleaning Cloths- Pack of 144",
        image: "https://images-na.ssl-images-amazon.com/images/I/91W3meJ95qL._AC_SX425_.jpg",
        price: 53.29,
        rating: 4.7,
    },
    {
        id: 4,
        title: "Arduino Nano [A000005]",
        image: "https://images-na.ssl-images-amazon.com/images/I/71%2BvvX%2BVCWL._AC_SL1500_.jpg",
        price: 19.9,
        rating: 4.7,
    },
    {
        id: 5,
        title: "CanaKit Raspberry Pi 4 8GB Starter MAX Kit - 64GB Edition (8GB RAM)",
        image: "https://images-na.ssl-images-amazon.com/images/I/81SP1QGYNZL._AC_SL1500_.jpg",
        price: 139.99,
        rating: 4.8,
    },
    {
        id: 6,
        title: "Apple Watch Series 5 (GPS, 44mm) - Gold Aluminum Case with Pink Sport Band",
        image: "https://images-na.ssl-images-amazon.com/images/I/71H8iifAnzL._AC_SL1500_.jpg",
        price: 499.99,
        rating: 4.8,
    },
    {
        id: 7,
        title: "USB C Hub Multiport Adapter - 7 in 1 Portable Space Aluminum Dongle",
        image: "https://images-na.ssl-images-amazon.com/images/I/71m8FtAfpjL._AC_SX569_.jpg",
        price: 24.99,
        rating: 4.6,
    },
    {
        id: 8,
        title: "DualShock 4 Wireless Controller for PlayStation 4 - Midnight Blue",
        image: "https://images-na.ssl-images-amazon.com/images/I/81wx5gvczgL._SX522_.jpg",
        price: 64.99,
        rating: 4.6,
    },
];
