const firebaseConfig = {
    apiKey: "AIzaSyDtsbdWDefOhjbWUJ_TcleEBknNULdfu-c",
    authDomain: "clone-b70cc.firebaseapp.com",
    databaseURL: "https://clone-b70cc.firebaseio.com",
    projectId: "clone-b70cc",
    storageBucket: "clone-b70cc.appspot.com",
    messagingSenderId: "982758219608",
    appId: "1:982758219608:web:bea04a8e7b4a7b9fbbcf2d",
    measurementId: "G-WM9RXJN3P0",
};

export default firebaseConfig;
